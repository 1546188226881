@value classes: "../../themes/theme.module.css";
@value lake500 from classes;

.titleBox {
  margin: 37px 24px 24px 24px;
}

.title {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-family: Merriweather;
  font-weight: bold;
  line-height: 1.167;
}

.NaviTab {
  text-transform: "unset";
}

.link {
  color: lake500;
}